@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
}